import {MenuCreateOptionInterface} from 'kernel/types/module'
class menu {
  private vm: Vue
  constructor() {
    this.vm = window.rootVm
  }

  $t(args: string) {
    return this.vm.$t(args)
  }

  get() : {[key: string]: any} {
    return {
      admin: [
        {
          label: this.vm.$t('page.product'),
          icon: 'fa fa-shapes',
          create: (options: MenuCreateOptionInterface) => options.hasRole(['ROLE_ADMIN', 'ROLE_PRODUCT']),
          group: [
            {
              label: this.vm.$t('page.product'),
              route: {
                name: 'product',
              },
            },
            {
              label: this.vm.$t('page.product_category'),
              route: {
                name: 'product-category',
              },
            },
          ],
        },
      ],
    }
  }
}

export default () => (new menu()).get()
